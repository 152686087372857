import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from '../components/Slider/SliderOne'
import About from '../components/About/home-one'
import Services from '../components/Services/home-one'
import WhyChooseUs from '../components/WhyChooseUs'
import Blog from '../components/Blog/home-one';
import LogoContainer from '../components/BrandLogo';
import ContactForm from '../components/ContactForm';


const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>AUDIS TECHNOLOGIES </title>
        <meta
          name="description"
          content="AUDIS TECHNOLOGIES"
        />
      </MetaTags>
      <LayoutOne>
        <About />
        <Slider />
        
        <Services />
        <Blog />
        <WhyChooseUs />
        <LogoContainer />
        <ContactForm />

      </LayoutOne>
    </Fragment>
  );
};

export default Home;
