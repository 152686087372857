import React from 'react';  
import {Link} from 'react-router-dom';
import List from "../../UI/List";
import LI from "../../UI/List/Item"
//Background Image
import pricingBg from '../../../assets/images/pricing_back.jpg'

const PricingItem = ({planName,currencyType,planPrice,subscribePlan,planFeatures,buttonLink}) => {
    return (
	<div className="col-md-4 col-sm-12"> 
		{/* Start: pricing-box */}
		<div className="pricing-box">
		{/* backgroundImage: `url("${pricingBg}")` */}
			<div className="pricing_heading" style={{backgroundColor: '#079b90'}} > 
				<h2><span>{currencyType}</span></h2>
			</div> 
			<div style={ {textAlign : 'justify', marginBottom: '40px',  marginTop: '20px', marginLeft:'15px', marginRight: '15px'}} >
				{
					planFeatures.map((feature,indx)=>(
						<span key={indx}>  {feature}</span>
					))
				}
			</div>
			<br></br> 
			{/* <div className="text-center"> 
				<Link to={`${process.env.PUBLIC_URL + buttonLink}`} className="more-link"> En savoir plus </Link>
			</div> */}
		</div>
		{/* End: pricing-box */}
	</div>
    );
};

export default PricingItem;