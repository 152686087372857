import React, { Fragment } from "react";
import SolutionData from  '../../data/Services/innovation-one';
const BlogContentWrap = ({ solution }) => {
    return (
        <Fragment>
    <div className="col-md-6 col-sm-12"> 
        <div className="about_list">
          <p className="about_para">{SolutionData.text}</p>
        </div>
    </div>  
            {/* <center>
                <div className="col-md-10 col-sm-12 blog-area">
                    <div className="blog-warp-1 blog_warp_lay_1">
                        <div className="blog_imgg">
                            <img src={require('../../assets/images/' + post.thumb)} alt="service" />
                        </div>
                        <div className="blog_content_warp">
                            <h5><a href={`${process.env.PUBLIC_URL + `/blog/${post.title.split(' ').join('-').toLowerCase()}?id=${post.id}`}`}>{post.title}</a></h5>
                            <p> {post.content} </p>
                            <div className="sing_blog_bottom_box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p> {post.contentSecondPart}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </center> */}
        </Fragment>
    );
};

export default BlogContentWrap;