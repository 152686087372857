import React, { Fragment } from "react";  
import PageHeader from '../../components/PageHeader'  
import BlogData from '../../data/Blog/blog' 
import BlogContent from "./BlogContent";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import SolutionData from "../../data/Blog/blog"
const blogDetails = ( ) => {

    const solutionID = new URLSearchParams(window.location.search).get("id");
	const solution = BlogData.find((blogItem) => blogItem.id === parseInt(solutionID));

	return (
		<Fragment>
            <PageHeader
				bgImg={require('../../assets/images/page_bg.jpg')}
				title={solution.title}
			/>
			<section className="about-section" id="about">
				<div className="container">
				<SectionTitle
									title={solution.title}
								/>
					<div className="row">
						<div className="col-md-6 col-sm-12 about_bottom_warp">
							<div className="about_list">
								
								<p>{solution.content} </p>
							</div>
							<div className="row about_list_warp">
								{SolutionData.map(about => (
									<BlogContent
										key={solution.id}
										title={solution.title}
										text={solution.text}
									/>
								))}
							</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<img src={require('../../assets/images/' + solution.thumb)} alt="service" />
						</div>
					</div>
				</div>
			</section>
			</Fragment>
	//   <Fragment>
	// 		<PageHeader
	// 			bgImg={require('../../assets/images/page_bg.jpg')}
	// 			title={post.title}
	// 		/>
	// 		<PageWrapper classes="blog_container single_blog_page">
	// 			<BlogContent
    //                 post={post}
	// 				title={post.title}
	// 				thumb={post.thumb}
	// 				excerpt={post.excerpt}
	// 				content={post.content} 
	// 				blockQuote={post.blockQuote} 
	// 				blockQuoteImg={post.blockQuoteImg} 
	// 				contentSecondPart={post.contentSecondPart} 
    //                 nextPost={nextPost}
    //                 prevPost={prevPost}
	// 				totalPost={BlogData.length}
    //             />
	// 		</PageWrapper>
	//   </Fragment>
	);
  };
  
  export default blogDetails;



