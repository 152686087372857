import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader';
import ServicesData from '../../data/Services/service-one';
import PageWrapper from "../../components/PageWrapper";
import ServiceContent from "./ServiceContent";
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const ServiceDetails = () => {

	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));

	return (
		
		<Fragment>
            <PageHeader
				bgImg={require('../../assets/images/page_bg.jpg')}
				title={service.title}
			/>
            <section className="about-section" id="about">
                <div className="container">
                <SectionTitle
                                title={service.title}
                            />
                <div className="row">
                    <div className="col-md-6 col-sm-12 about_bottom_warp">
                        <div className="about_list">
                           
                            <p>{service.description} </p>
                        </div>
                        <section id="work" className="work_section_v3">
                            <div>
                                <div className="projects-list ">
                                    <Tab.Container defaultActiveKey={0}>
                                        <Nav variant="pills" className="acount__nav nav nav-pills">
                                            {
                                                service.contentList.map(function (item, id) {
                                                    return <Nav.Item>
                                                        <Nav.Link eventKey={id}> {item.itemTitle} </Nav.Link>
                                                    </Nav.Item>
                                                })}
                                        </Nav>
                                        <Tab.Content>
                                            {
                                                service.contentList.map(function (item, id) {
                                                    return <Tab.Pane eventKey={id}>
                                                        {item.itemDesc}
                                                    </Tab.Pane>
                                                })}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>

                        </section>

                    </div>
                    <div className="col-md-6 col-sm-12">
                        <img src={require('../../assets/images/' + service.thumb)} alt="service" />

                    </div>
                </div>
            </div>
            </section>

        </Fragment>
			
	);
};
export default ServiceDetails;



