import React from 'react';   
import AboutList from "./AboutList"; 
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";

// About Image
import aboutImg from '../../../assets/images/blog11.jpg';

const AboutContent = () => {

  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
           {/* Start: Heading */}
                        <SectionTitle 
                        title=" Présentation "  
                        />
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list">
                     
                        <p>AUDIS TECHNOLOGIES est  un éditeur de logiciels du monde de l'intelligence artificielle et le monitoring des services Cloud. Elle s’est développée autour de projets porteurs dans ces domaines, issus du monde de la recherche et de l'innovation.  
                        </p><p>AUDIS TECHNOLOGIES est aussi un acteur conseil, des services technologiques,  qui accompagne ses clients dans la réussite de la transformation de leurs métiers et de leurs systèmes d’information, notamment pour l’adaptation au Cloud Computing.</p>
                     </div>

                    <div className="row about_list_warp"> 

                        {/*Start:   About List */} 
                        {AboutData.map(about=>(
                            <AboutList 
                                key={about.id} 
                                title={about.title} 
                                text={about.text}  
                                icon={about.icon}  
                            />
                        ))}
                        {/*End:  About List*/}
                        
                    </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={aboutImg} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutContent;
