import React from 'react';
 
const COntactInfo = () => {

    return (

      <div className="row">
        <div className="bottom_contact col-sm-6 col-xs-12"><i className="icon_pin_alt"></i>
            <p>ADRESSE : </p>
            <h4>32, RUE PIERRET,</h4>
            <h4> 92200 NEUILLY-SUR-SEINE  </h4>
        </div>
   

        <div className="bottom_contact col-sm-6 col-xs-12"><i className="icon_phone"></i>
            <p>NUMERO : </p>
            <h4> +33 (0)1 40 07 16 79</h4>
        </div>
      </div>

    );
}

export default COntactInfo;