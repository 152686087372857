import React,{Component} from 'react';
import {Form} from 'react-bootstrap';
import {API_URL} from '../../env.json';
const errStyle = {
  color: 'rgb(228, 8, 8)',
  fontSize: '12px',
};

class CandidatureForm extends Component{

    constructor(){
        super();
        this.state={  selectedFile: null ,cv: null, email:'',name:'',subject:'',message:'',email_err:'',name_err:'',subject_err:'',message_err:'',cv_err:'',return_msg:'',flag : false }

         this.handleChangeEmail = this.handleChangeEmail.bind(this);
         this.handleChangeName = this.handleChangeName.bind(this);
         this.handleChangeSubject = this.handleChangeSubject.bind(this);
         this.handleChangeMessage = this.handleChangeMessage.bind(this);
         this.handleChangeFile = this.handleChangeFile.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeEmail(e){
        this.setState({email:  e.target.value});
        var EmailReg =  /.+@.+\.[A-Za-z]+$/;
        if(e.target.value === '') 
          this.setState({email_err: 'Ce champ est obligatoire'});
        else if(EmailReg.test(e.target.value)) 
          this.setState({email_err: ''});
        else 
          this.setState({email_err: 'Email non valide'});
      }
      onChangeHandler=event=>{
        this.setState({
          selectedFile: event.target.files[0],
          loaded: 0,
        })
      }

      handleChangeName(e){
        this.setState({name:  e.target.value});
          if(e.target.value === '') 
            this.setState({name_err: 'Ce champ est obligatoire'});
          else 
            this.setState({name_err: ''});
      }
      handleChangeSubject(e){
        this.setState({subject:  e.target.value});
          if(e.target.value === '') 
            this.setState({subject_err: 'Ce champ est obligatoire'});
          else 
            this.setState({subject_err: ''});
      }
    
      handleChangeMessage(e){
        this.setState({message:  e.target.value});
          if(e.target.message === '') 
            this.setState({message_err: 'Ce champ est obligatoire'});
          else 
            this.setState({message_err: ''});
      }
      handleChangeFile(e){
        this.setState({cv:  e.target.files[0]});
          if(e.target.cv === null) 
            this.setState({cv_err: 'Ce champ est obligatoire'});
          else 
            this.setState({cv_err: ''});
      }

      handleSubmit(e){
        const url = `${API_URL}/api/send/candidature`
        
            const data = {
              name: this.state.name,
              email:this.state.email,
              message:this.state.message
            } 
          e.preventDefault();
          var EmailReg =  /.+@.+\.[A-Za-z]+$/;
            if(this.state.name === '') 
                this.setState({name_err: 'Ce champ est obligatoire'});
            if(this.state.email === '')
                this.setState({email_err: 'Ce champ est obligatoire'});
            if(!EmailReg.test(this.state.email)) 
                this.setState({email_err: 'Email non valide'});
            if(this.state.subject === '')
               this.setState({subject_err: 'Ce champ est obligatoire'});
            if(this.state.message === '')
               this.setState({message_err: 'Ce champ est obligatoire'});
            if(this.state.cv == null)
               this.setState({cv_err: 'Ce champ est obligatoire'});

               if(this.state.name === '' || this.state.email === ''|| !EmailReg.test(this.state.email) || this.state.subject === '' || this.state.message === ''|| this.state.cv === null)
               {
                  this.setState({return_msg: 'Merci de remplir correctement tous les champs obligatoires', flag: true });
               }
               else
               {
                let formData = new FormData();
                formData.append('name', this.state.name);
                formData.append('email', this.state.email);
                formData.append('cv', this.state.cv);
                formData.append('message', this.state.message);
        
                fetch(url,{
                  method: "POST", 
                  body: formData,
                  
              }) 
              .then( (res) => (res.json()))
              .catch(error =>{ })
              .then(); 
                  
                  this.setState({return_msg: 'Merci, votre candidature a bien été envoyée', flag: true}); 
                  this.resetForm()
              }
      
    }
    resetForm(){
      this.setState({name: '', email: '',subject:'', message: ''})
    }
  render(){

    return(
  
            <div className="contact-form">
              <div id="message">
                  {this.state.flag ? 
                        <div className="alert ">
                           {this.state.return_msg ==='Merci, votre candidature a bien été envoyée' ? 
                        <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                        </div>
                    :  <div className="alert alert-danger">
                    <strong>{this.state.return_msg}</strong>
                    </div>}
                        </div>
                    : null}
                   
              </div> 
              <form method="post" id="contactForm">
                  <div className="row">
                  <div className="col-md-6 col-sm-12">
                      <div className="input-group"> 
                          <input type="text" id="name" value={this.state.name} onChange={this.handleChangeName} className="form-control" placeholder="Nom Prénom " /> 
                          <span style ={errStyle} id="err">{this.state.name_err}</span>
                      </div>
                      <div className="input-group"> 
                          <input type="text" className="form-control" value={this.state.email} onChange={this.handleChangeEmail} id="email" placeholder="Email" />
                          <span style ={errStyle} id="err">{this.state.email_err}</span>
                      </div>                            
                      <div className="input-group"> 
                          <input type="text" id="tel" value={this.state.subject} onChange={this.handleChangeSubject} className="form-control" placeholder="Numéro" />
                          <span style ={errStyle} id="err">{this.state.subject_err}</span>
                      </div> 
                  </div> 
                  <div className="form-group col-md-6 col-sm-12">
                      <div className="input-group input_group_full_width"> 
                          <textarea name="comments" id="message" value={this.state.message} onChange={this.handleChangeMessage} rows="6" className="form-control" placeholder="Message"></textarea>
                          <span style ={errStyle} id="err">{this.state.message_err}</span>
                      </div> 
                  </div> 
              </div> 
              <div className="form-group col-md-6 col-sm-12">
                    <Form.Group>
                      <Form.File
                        className="position-relative"
                        required 
                        name="cv"
                        label="Ajouter votre CV * :"
                        onChange={this.handleChangeFile}
                        id="validationFormik107"
                        feedbackTooltip
                      />
                    </Form.Group>
                    <span style ={errStyle} id="err">{this.state.cv_err}</span>
                  </div>
                 
                  <div className="col-sm-12">
                    <button type="button" name="send" onClick={this.handleSubmit} className="submit-button btn btn-chos" value="Submit">Envoyer</button>
                    <div id="simple-msg"></div>
                  </div>
              </form> 
            </div> 
        )
    }
}
export default CandidatureForm;