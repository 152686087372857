import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader';
import ServicesData from '../../data/Services/offre-one.json';
import PageWrapper from "../../components/PageWrapper";
import ServiceContent from "./ServiceContent";

const ServiceDetails1 = () => {
	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	return (
		<Fragment>
			<PageHeader
				bgImg={require('../../assets/images/page_bg.jpg')}
				parentMenu="Offres"
				parentMenuLink = "offres"
			/>
			<PageWrapper classes="single_service_section">
				<ServiceContent
					service={service}
				/>
			</PageWrapper>
		</Fragment>
	);
};

export default ServiceDetails1;



