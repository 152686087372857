import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import CandidatureForm from '../components/CandidatureForm'
const CandidaturePage = () => {
    
    return(

        <Fragment>
            <MetaTags>
              <title>Audis Technologies | Contact </title>
              <meta
                name="description"
                content="AUDIS TECHNOLOGIES"
              />
            </MetaTags>

            <LayoutOne>
              {/* <PageHeader
                bgImg={require('../assets/images/page_bg.jpg')}
                title="Candidature spontanée" 
               
              /> */}
              
              <CandidatureForm />
 
            </LayoutOne>
        </Fragment>

      );
  }

     

export default CandidaturePage;   