import React from 'react';
import SlickSlider from '../../components/UI/Carousel/slick'
import ServicesData from '../../data/Blog/blog'  
import BlogContent from "./BlogContent";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
const blogDetails = () => {  

    const settings = {
        dots: false,
        arrows:false,
        infinite: true, 
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              } 
 
            ]
      };

 
        return (

        <section className="service_section" id="agenda">
            <div className="container">
        <SectionTitle
          title="Solutions"
        />
      
                <div className="row" id="service"> 
                    <div className="col"> 
                        <SlickSlider settings={settings}>
                            {
                                ServicesData.map(service => (
                                    <div key={service.id}>
                                        <BlogContent 
                                        key={service.id} 
                                        id={service.id} 
                                        title={service.title} 
                                        shortDesc={service.shortDesc} 
                                        thumb={service.thumb}  
                                        icon={service.icon}  
                                        btnText={service.btnText} 
                                        />
                                    </div> 
                                ))
                            }
                        </SlickSlider>
                    </div> 
                </div> 
            </div> 
        </section>    
        );
    }
;

export default blogDetails;


