import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";  
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
            <div className="col-lg-3 col-sm-12">
					<Widget title="Contact">
                    <p>ADRESSE : </p>
                    <p>32, RUE PIERRET, </p> <p> 92200 NEUILLY-SUR-SEINE  </p>
                    <p>NUMERO : </p>
                    <p> +33 (0)1 40 07 16 79</p>
                    </Widget>
                </div> 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Expertise">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/expertise/cloud?id=1"}>Cloud</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/expertise/bigData?id=2"}>BI/Big Data</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/expertise/intelligenceArtificiel?id=3"}>IA</Link>
                            </LI> 
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/expertise/transforationDigitale?id=4"}>Transformation Digitale</Link>
                            </LI> 
                        </List>
                    </Widget> 
                </div> 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Solutions">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/solution/CloudSight?id=2"}>CloudSight</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/solution/BigSight?id=1"}>BigSight</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/solution/NetSight?id=3"}>NetSight</Link>
                            </LI> 
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/solution/hauteDisponibilité?id=4"}>Haute disponibilité</Link>
                            </LI> 
                        </List>
                    </Widget> 
                </div> 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Recherche et Innovation">
                        <List classes="recent-post">
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/innovation/intelligenceArtificielle?id=1"}>Intelligence artificielle </Link>
                            </LI>
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/innovation/Cloud?id=2"}>Cloud </Link>
                            </LI>
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/innovation/innovation?id=3"}>Innovation </Link>
                            </LI>
                        </List>
                    </Widget> 
                </div>
           
                
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} AUDIS-TECHNOLOGIES <Link to={process.env.PUBLIC_URL + "/"}> </Link>
                    </div> 

                    {/* <div className="footer_social"> 
                        <ul className="footer_social_icons">
                        <li> <a href="//facebook.com"><i className="fa fa-facebook"></i></a> </li>
                        <li> <a href="//twitter.com"><i className="fa fa-twitter"></i></a> </li>
                        </ul>
                    </div> */}

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="arrow_triangle-up"></span>
                    </button> 
                </div> 

            </div>
        </div> 

    </footer> 

        )

}

 
export default Footer;